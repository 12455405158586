import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { PortfolioChart, PortfolioMetrics } from "../../../components/widgets/portfolio-ref";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` See Also `}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/momentum-mean-reversion/"
          }}>{`Momentum & Mean-Reversion`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/volatility-targeting/"
          }}>{`Volatility Targeting`}</a></li>
      </ul>
      <p><strong parentName="p">{` Related Portfolios `}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-tr/"
          }}>{`All-Stars Total Return`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-weekly/"
          }}>{`All-Stars Weekly`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-monthly/"
          }}>{`All-Stars Monthly`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-stocks/"
          }}>{`All-Stars Stocks`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-all-stars-lev/"
          }}>{`All-Stars Leveraged`}</a></li>
      </ul>
    </Aside>
    <h2>{`Overview`}</h2>
    <p>{`On `}<em parentName="p">{`TuringTrader.com`}</em>{`, we track a growing number of tactical portfolios. For some, this might represent the `}<a parentName="p" {...{
        "href": "https://www.ted.com/talks/barry_schwartz_the_paradox_of_choice?language=en"
      }}>{`Paradox of Choice`}</a>{`, leading to paralysis and not liberation. What if I can't decide? What if I don't make the best decision?`}</p>
    <p>{`But the reason why we track many portfolios is only partially to provide more choices. The more important reason is diversification.`}</p>
    <div css={`height:0;clear:both;`} />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0eb33e334304ccb96bb15c4200e7214f/68b3c/candy-store.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABSnasqrDAn//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhESEyEi/9oACAEBAAEFAtUEWNEMhXcuY6f/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EAB0QAAIBBAMAAAAAAAAAAAAAAAAzAQIRIZEjUaH/2gAIAQEABj8CjhE4v0LG06GxoZ4f/8QAHBAAAgICAwAAAAAAAAAAAAAAASEAETFxQaGx/9oACAEBAAE/IUZ3UyifghWZg6goKErcRLlJMGsBz2n/2gAMAwEAAgADAAAAEEsP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPxCKMf/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxCC5f/EABwQAQACAgMBAAAAAAAAAAAAAAERIQAxQVFhkf/aAAgBAQABPxAG7rlNIvV4prNoVHje6cMOHILt9wQJCKRE0n7gtJLBJl3WJ22sPt17n//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: better diversification with all-stars portfolios",
            "title": "TuringTrader.com: better diversification with all-stars portfolios",
            "src": "/static/0eb33e334304ccb96bb15c4200e7214f/e5166/candy-store.jpg",
            "srcSet": ["/static/0eb33e334304ccb96bb15c4200e7214f/f93b5/candy-store.jpg 300w", "/static/0eb33e334304ccb96bb15c4200e7214f/b4294/candy-store.jpg 600w", "/static/0eb33e334304ccb96bb15c4200e7214f/e5166/candy-store.jpg 1200w", "/static/0eb33e334304ccb96bb15c4200e7214f/d9c39/candy-store.jpg 1800w", "/static/0eb33e334304ccb96bb15c4200e7214f/df51d/candy-store.jpg 2400w", "/static/0eb33e334304ccb96bb15c4200e7214f/68b3c/candy-store.jpg 3704w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Dimensions of Diversification`}</h2>
    <p>{`As Nobel Prize laureate `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Harry_Markowitz"
      }}>{`Harry Markowitz`}</a>{` put it, "Diversification is the only free lunch" in investing. The underlying idea is simple: By combining multiple investments into portfolios, we can reduce `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/i/idiosyncraticrisk.asp"
      }}>{`idiosyncratic risk`}</a>{` and consequently improve `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/r/riskadjustedreturn.asp"
      }}>{`risk-adjusted returns`}</a>{`.`}</p>
    <h3>{`Diversification across Titles`}</h3>
    <p>{`At the most basic level, we achieve diversification by investing in more than one instrument, e.g., a stock, at a time. This way, we reduce the risk of losing our principal, should the one company we invested in get into trouble. Instead of investing in multiple hand-picked stocks, we can also buy shares of an `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/e/etf.asp"
      }}>{`Exchange-Traded Fund`}</a>{` (ETF), packaging numerous stocks in a single diversified instrument.`}</p>
    <h3>{`Diversification across Asset Classes`}</h3>
    <p>{`With this stock-market ETF, we have achieved some level of diversification against individual company risk. But when the whole stock market takes a downturn, this won't protect us because our ETF only represents a single asset class. To reach the next level of diversification, we add more asset classes to our portfolio: Most importantly, bonds, but possibly also real-estate, gold, commodities, cryptocurrencies, or derivatives. Such cross-asset portfolios typically do better across a wider variety of economic scenarios. One example of such a portfolio is `}<a parentName="p" {...{
        "href": "/portfolios/robbins-all-seasons/"
      }}>{`Tony Robbins' All-Seasons Portfolio`}</a>{`.`}</p>
    <p>{`We can tweak and optimize the asset allocation for `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/b/buyandhold.asp"
      }}>{`buy and hold portfolios`}</a>{` but have otherwise reached the limits of diversification. However, TuringTrader.com is all about tactical asset allocation, providing us with more dimensions of possible diversification.`}</p>
    <h3>{`Diversification across Trading Styles`}</h3>
    <p><a parentName="p" {...{
        "href": "/help/tactical-asset-allocation/"
      }}>{`Tactical portfolios`}</a>{` are based on conviction: firmly held beliefs on how the markets work and what drives asset prices. There are many different trading styles, which form an excellent basis for diversification. `}<a parentName="p" {...{
        "href": "/articles/momentum-mean-reversion/"
      }}>{`Trend-following and momentum`}</a>{` is probably the most widely used style, followed by `}<a parentName="p" {...{
        "href": "/articles/momentum-mean-reversion/"
      }}>{`mean-reversion`}</a>{`. `}<a parentName="p" {...{
        "href": "/articles/volatility-targeting/"
      }}>{`Volatility-targeting`}</a>{` is another, less frequently used style. By combining different trading styles, we look at the markets from various angles, reduce correlation, and improve the ability to deal with ambiguity.`}</p>
    <h3>{`Diversification over Time`}</h3>
    <p>{`In addition to the trading style, we can also diversify over time. Typical tactical portfolios trade once per day, week, or month. By offsetting the times at which we place our trades, we reduce the effect of timing luck. A simple way of achieving this is portfolio tranching. For example, we can take a monthly portfolio but trade a quarter of the capital every week. Another way to diversify over time is to combine portfolios that differ in their rebalancing frequency. In any case, this type of diversification further helps to deal with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Black_swan_theory"
      }}>{`black swan events`}</a>{` and ambiguity.`}</p>
    <PortfolioChart portfolio="tt-all-stars-tr" mdxType="PortfolioChart" />
    <h2>{`TuringTrader's All-Stars Portfolios`}</h2>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` caters to DIY investors. We believe that these investors prefer simple solutions over the complexity of user-configurable meta-portfolios. Our new `}<em parentName="p">{`All-Stars Portfolios`}</em>{` are just that: ready-to-use meta-portfolios, combining several of our best portfolios based on our insight into their inner mechanics.`}</p>
    <p>{`Let's look at one of these meta portfolios in more detail. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` portfolio combines multiple of our `}<em parentName="p">{`Premium`}</em>{` portfolios, achieving broad diversification over asset classes, trading style, and time:`}</p>
    <small>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}><a parentName="th" {...{
                "href": "/portfolios/tt-round-robin/"
              }}>{`Round-Robin`}</a></th>
            <th parentName="tr" {...{
              "align": null
            }}><a parentName="th" {...{
                "href": "/portfolios/tt-mean-kitty/"
              }}>{`Mean Kitty`}</a></th>
            <th parentName="tr" {...{
              "align": null
            }}><a parentName="th" {...{
                "href": "/portfolios/tt-vix-spritz/"
              }}>{`VIX Spritz`}</a></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Asset Classes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sector ETFs, Stylebox ETFs, Bond ETFs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Sector ETFs, SPY, AGG`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`SPY, TLT, GLD, DBC, TIP, VXX`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Trading Style`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Momentum`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Mean-Reversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Volatility-Targeting`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Trading Frequency`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Weekly`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Daily`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Daily`}</td>
          </tr>
        </tbody>
      </table>
    </small>
    <p>{`The resulting meta-portfolio's performance is right where we would expect it: at the average of the three individual strategies. But due to the portfolio's improved diversification, the `}<a parentName="p" {...{
        "href": "/help/charts-metrics/"
      }}>{`Ulcer Index`}</a>{`, a measure for portfolio risk, is lower than that of its components. As Harry Markowitz once put it, diversification is "the only free lunch in finance."`}</p>
    <ButtonPrimary text="All-Stars Portfolios" to="/portfolios/?tag=meta" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      